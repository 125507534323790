import React from "react";
import { Helmet } from "react-helmet";

function Head() {
    return (
        <Helmet>
            <title>Stomp home</title>
            <meta property="theme-color" name="theme-color" content="#BE71D6" />
            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
            <link rel="icon" href="/boots.svg" />
            <meta name="description" content="Little Stomp, Big Hop" />

            {/*Apple*/}
            <meta name="apple-mobile-web-app-capable" content="yes"></meta>
            <link rel="apple-touch-icon" sizes="60x60" href="https://www.littlestomper.com/little-stomper-high-resolution-logo-transparent.png" />

            {/*<!-- Google / Search Engine Tags -->*/}
            <meta itemProp="name" content="Stomp Home" />
            <meta itemProp="description" content="Little Stomp, Big Hop" />
            <meta
                itemProp="image"
                content="https://www.littlestomper.com/little-stomper-high-resolution-logo-transparent.png"
            />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:title" content="Stomp Home" />
            <meta property="og:description" content="Little Stomp, Big Hop" />
            <meta
                property="og:image"
                content="https://www.littlestomper.com/little-stomper-high-resolution-logo-transparent.png"
            />
            <meta property="og:url" content="https://www.littlestomper.com" />
            <meta property="og:type" content="website" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:title" content="Stomp Home" />
            <meta name="twitter:description" content="Little Stomp, Big Hop" />
            <meta
                name="twitter:image"
                content="https://www.littlestomper.com/little-stomper-high-resolution-logo-transparent.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export default Head