import React, { useState } from "react";
import styles from "../../styles/nav.module.scss";
// import { GoogleLogin } from "react-google-login";
// import AppleLogin from 'react-apple-login';

const LoginForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle login logic here, e.g., sending data to backend
        console.log("Login form submitted with data:", formData);
        onClose(); // Close the form after submission
    };

    // const responseGoogle = (response) => {
    //     console.log(response);
    //     // Handle Google login response here
    // };

    // const appleLoginSuccess = (response) => {
    //     console.log(response);
    //     // Handle Apple login response here
    // };

    return (
        <div className={styles.loginform__overlay}>
            <div className={styles.loginform__container}>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form__group}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.form__group}>
                        <button type="submit">Login</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
                <div>
                    {/* <GoogleLogin
                        clientId="YOUR_GOOGLE_CLIENT_ID"
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    /> */}
                    {/* <AppleLogin
                        clientId="YOUR_APPLE_CLIENT_ID"
                        redirectURI="https://your-redirect-uri.com"
                        onSuccess={appleLoginSuccess}
                        onFailure={console.error}
                        responseType={"code"}
                        responseMode={"form_post"}
                    /> */}
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
