import React, { useState } from "react";
import styles from "../../styles/nav.module.scss";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import LoginForm from "../login/index";
import SignUpForm from "../signup/index";

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const menuToggler = () => setMenuOpen((prev) => !prev);

  const handleSignUp = () => {
    setShowSignUpForm(true);
  };

  const handleLogin = () => {
    setShowLoginForm(true);
  };

  return (
    <div
      style={{
        background: `rgba(255, 255, 255, 1)`,
        color: `black`,
      }}
      className={styles.header}
    >
      <div className={styles.header__content}>
        <div className={styles.logo}>
          <a href={"#home"}>
            <img
              src={"/boots.svg"}
              alt={"Little Stompers, Click to go home/ top of page."}
              width={70}
              height={70}
            />
          </a>
        </div>
        <div>
          <nav
            style={{
              background: `rgba(255, 255, 255, 1)`,
            }}
            className={`${styles.nav} ${menuOpen ? styles[`nav--open`] : {}}`}
          >
            <a className={styles.nav__item} href={"#home"}>
              Home
            </a>
            <a className={styles.nav__item} href={"#about"}>
              About
            </a>
            <a className={styles.nav__item} href={"#pricing"}>
              Pricing
            </a>
            <a className={styles.nav__item} href={"#book"}>
              Book
            </a>
            <a className={styles.nav__item} href={"#map"}>
              Map
            </a>
            <div className={styles.nav__button__container}>
              <Button onClick={handleSignUp}>Sign Up</Button>
              <Button onClick={handleLogin}>Login</Button>
            </div>
          </nav>
        </div>
        <div>
          <div className={styles.header__button__container}>
            <Button onClick={handleSignUp}>Sign Up</Button>
            <Button onClick={handleLogin}>Login</Button>
          </div>
          <button className={styles.header__toggler} onClick={menuToggler}>
            {!menuOpen ? <BiMenuAltRight /> : <AiOutlineCloseSquare />}
          </button>
        </div>
      </div>
      {showSignUpForm && <SignUpForm onClose={() => setShowSignUpForm(false)} />}
      {showLoginForm && <LoginForm onClose={() => setShowLoginForm(false)} />}
    </div>
  );
}

const Button = ({ children, onClick }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
};

export default Nav;
