import React from "react";
import style from "./styles/nav.module.scss";

function Home() {
    return (
        <div className="App">
            <section id="home">
                <div className="aligned-center">
                    <img src="/logo-no-background.png" alt="Little Stompers logo" height={250} />
                    <h1>Professional Babysitting</h1>
                    <div className="button-container">
                        <button className={style.button} onClick={booking}>Stomp Now</button>
                        <button className={style.button} onClick={billing}>Stomped Billing</button>
                    </div>
                </div>
            </section>
            <section id="about">
                <div className="aligned-centerS2">
                    <h2>About</h2>
                    <p>Welcome to Little Stompers – Your Trusted Partner in Nurturing Happy Beginnings!</p>
                    <p>At Little Stompers, we understand the importance of providing a safe, loving, and stimulating environment for your little ones. With a commitment to excellence in childcare, we have become a beacon of trust for families seeking nursery, babysitting, and child-minding services.</p>
                    <p>Our journey began with a passion for early childhood development and a vision to create a haven where children can grow, learn, and play in an environment that feels like a second home. Established with love and care, Little Stompers is more than just a childcare facility – it's a community built on warmth, trust, and shared values.</p>
                    <p>At Little Stompers, we believe in sowing the seeds of a bright future during the formative years. Join us in creating a nurturing space where every little stomp brings joy, laughter, and the promise of a beautiful journey ahead. Discover the warmth of our community – where your child's happiness and growth are at the heart of everything we do.</p>
                </div>
            </section>
            <section id="pricing">
                <div className="aligned-centerS3">
                    <div className="card">
                        <h2>Standard Rate</h2>
                        <p>The Standard Rate for under 5. Hourly cost.</p>
                        <h3>£11</h3>
                    </div>
                    <div className="card">
                        <h2>Standard Rate 5+</h2>
                        <p>The Standard Rate for over 5. Hourly cost.</p>
                        <h3>£15</h3>
                    </div>
                    <div className="card">
                        <h2>Extra 5-</h2>
                        <p>Extra charge for each under 5. Hourly cost.</p>
                        <h3>£2</h3>
                    </div>
                    <div className="card">
                        <h2>Extra 5+</h2>
                        <p>Extra charge for each over 5. Hourly cost.</p>
                        <h3>£5</h3>
                    </div>
                </div>
            </section>
            <section id="book">
                <div className="form-container">
                    <form action="https://formsubmit.co/contact@littlestomper.com" method="post" target="_blank" className="form-style">
                        <div className="form-child">
                            <div>
                                <img src="/logo-no-background.png" alt="" height={200} />
                                <h1>Booking Form</h1>
                            </div>
                            <div className="form-block">
                                <h2>Client Details</h2>
                                <div className="form-item">
                                    <label htmlFor="fname">First Name:</label>
                                    <input type="text" name="firstname" id="fname" placeholder="First name" required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="lname">Last Name:</label>
                                    <input type="text" name="lastname" id="lname" placeholder="Last name*" required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="form-email">Email:</label>
                                    <input type="email" name="email" id="form-email" placeholder="example@example.com*" required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="tel">Phone:</label>
                                    <input type="tel" pattern="\d*" name="tel" id="tel" placeholder="0000000000" />
                                </div>
                            </div>
                            <div className="form-block">
                                <h3>Location</h3>
                                <div className="form-item">
                                    <label htmlFor="address">Address:</label>
                                    <input type="text" name="address" id="address" placeholder="First Line of Address*" required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="postcode">Postcode:</label>
                                    <input type="text" name="postcode" id="postcode" placeholder="Postcode*" required />
                                </div>
                            </div>
                            <div className="form-block">
                                <h2>Child Details</h2>
                                <div className="form-item">
                                    <label htmlFor="number">Number of Children:</label>
                                    <input type="number" pattern="\d*" name="number" id="number" placeholder="Number of Children*" required />
                                </div>
                                <div>
                                    <h3>Ages</h3>
                                    <div className="form-item">
                                        <label htmlFor="1child">First Child:</label>
                                        <input type="number" pattern="\d*" name="1child" id="1child" placeholder="First Child*" required />
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="2child">Second Child:</label>
                                        <input type="number" pattern="\d*" name="2child" id="2child" placeholder="Second Child" />
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="3child">Third Child:</label>
                                        <input type="number" pattern="\d*" name="3child" id="3child" placeholder="Third Child" />
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="4child">Fourth Child:</label>
                                        <input type="number" pattern="\d*" name="4child" id="4child" placeholder="Fourth Child" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-block">
                                <h2>Booking Details</h2>
                                <div className="form-item">
                                    <label htmlFor="date">Date and Time:</label>
                                    <input type="datetime-local" name="date" id="date" required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="hours">Number of Hours:</label>
                                    <input type="number" pattern="\d*" name="hours" id="hours" placeholder="Number of Hours*" required />
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="_next" value="https://www.littlestomper.com" />
                        <input className="form-button" type="submit" value="Book Now" />
                    </form>
                </div>
            </section>
            <section id="map">
                <iframe
                    title='M16 map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19003.890036065794!2d-2.298366705336764!3d53.459769371219345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bade198f6a2ab%3A0xa06b7a1e162e18f9!2sOld%20Trafford%2C%20Stretford%2C%20Manchester!5e0!3m2!1sen!2suk!4v1705164815431!5m2!1sen!2suk"
                    width="100%"
                    height="400"
                    loading="lazy"
                ></iframe>
            </section>
        </div>);
}

export default Home;

const booking = () => {
    document.location.href =
        "#book";
};
const billing = () => {
    document.location.href =
        "https://pay.littlestomper.com/p/login/14k5mS1vF5h118YaEE";
}
