import React from "react";
import { FaFacebook } from "react-icons/fa";
// import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
function Footer() {
  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <hr color="#BE71D6" />
          <div className="sb__footer-links_div"></div>
          <div className="sb__footer-links_div">
            <h4>Contact</h4>
            <a href="mailto:contact@littlestomper.com">
              <p>Email: <span>Contact@LittleStomper.com</span></p>
            </a>
            <a href="tel:07432642980">
              <p>Number: <span>07432 642980</span></p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Socials</h4>
            <div className="socialmedia">
              <a href="https://www.facebook.com/OfficialStomp/">
                <FaFacebook size={40} color="#BE71D6" />
              </a>
              {/* <a href="https://instagram.com/wildnetworkuk">
                <AiFillInstagram size={40} color="#BE71D6" />
              </a>
              <a href="https://twitter.com/WILDNetworkUK">
                <AiFillTwitterCircle size={40} color="#BE71D6" />
              </a> */}
            </div>
            {/* <a href="/links">See more...</a> */}
          </div>
          <div className="sb__footer-links_div">
          </div>
        </div>

        <hr color="#BE71D6" />

        <div className="sb__footer-below">
          <div className="sb__footer-copyright">
            <p>
              © {new Date().getFullYear()} Little Stompers Ltd. All rights reserved
              built and designed by Mr A Hindle.
            </p>
          </div>
          <div className="sb__footer-below-links">
            <a href="/terms">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </a>
            <a href="/privacy">
              <div>
                <p>Privacy</p>
              </div>
            </a>
            <a href="/security">
              <div>
                <p>Security</p>
              </div>
            </a>
            <a href="/cookie">
              <div>
                <p>Cookie Declaration</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
