import React, { useState } from "react";
import styles from "../../styles/nav.module.scss";

const SignUpForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://www.littlestomper.com/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            console.log(response)

            if (response.ok) {
                console.log("Sign-up successful");
                onClose(); // Close the form after successful sign-up
            } else {
                console.error("Sign-up failed");
            }
        } catch (error) {
            console.error("Error during sign-up:", error);
        }
    };

    return (
        <div className={styles.loginform__overlay}>
            <div className={styles.loginform__container}>
                <h2>Sign Up</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form__group}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.form__group}>
                        <button type="submit">Sign Up</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUpForm;